
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import AvailableProfileModel from 'modules/App/models/AvailableProfile';
import ContactModel from 'modules/App/models/Contact';
import SearchedProfileModel from 'modules/App/models/SearchedProfile';
import HomePage from 'modules/Marketing/pages/HomeV2';
import UserModel from 'modules/User/models/User';
import { getServerSession } from 'modules/auth/server';
import type { GetServerSideProps } from 'next';
import dbConnect from 'utils/db';
const getServerSideProps: GetServerSideProps = async (context) => {
    await dbConnect();
    const session = await getServerSession(context);
    if (context.locale === 'en') {
        return {
            redirect: {
                destination: '/fr',
                permanent: false,
            },
        };
    }
    const nbMembers = await UserModel.countDocuments({ roles: 'member' });
    const nbContacts = await ContactModel.countDocuments({ candidate: { $exists: true } });
    const nbSearchedProfiles = await SearchedProfileModel.countDocuments({ publishedAt: { $exists: true, $ne: null } });
    const nbAvailableProfiles = await AvailableProfileModel.countDocuments({ publishedAt: { $exists: true, $ne: null } });
    const bountyAmount = (await SearchedProfileModel.aggregate([
        { $match: { publishedAt: { $exists: true, $ne: null } } },
        {
            $group: {
                _id: null,
                totalBountyStart: { $sum: '$bountyStart' },
                totalBountyConfirm: { $sum: '$bountyConfirm' },
            },
        },
        {
            $project: {
                bounty: { $add: ['$totalBountyStart', '$totalBountyConfirm'] },
            },
        },
    ]))[0]?.bounty || 0;
    // if (session) {
    //   return {
    //     redirect: {
    //       destination: '/fr/my-missions',
    //       permanent: false,
    //     },
    //   };
    // }
    return {
        props: { session: JSON.parse(JSON.stringify(session)), stats: { nbMembers, nbContacts, nbSearchedProfiles, nbAvailableProfiles, bountyAmount } },
    };
};
export default HomePage;

    async function __Next_Translate__getServerSideProps__1916e22ca86__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__1916e22ca86__ as getServerSideProps }
  